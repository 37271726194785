import { ApplicationRef, Injectable, InjectionToken, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, timer, of, Subject, EMPTY, throwError } from 'rxjs';
import { expand, switchMap, takeUntil, catchError, retryWhen, delay, concatMap, first, startWith } from 'rxjs/operators';
import { retryBackoff } from 'backoff-rxjs';

export interface CaptchaServiceConfigurationInterface {
    baseUrl: URL;
}

export const captchaServiceConfiguration = new InjectionToken<CaptchaServiceConfigurationInterface>('captchaServiceConfiguration');

export interface Challenge {
    siteKey: string;
    token: string;
    refreshSubject: Subject<void>;
}

export interface ImageChallenge extends Challenge {
    image: string;
}

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {

    private http = inject(HttpClient);
    private baseUrl = inject(captchaServiceConfiguration).baseUrl.toString();
    private isStable = inject(ApplicationRef).isStable;

    public solve(challenge: Challenge, solution: string): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/${challenge.siteKey}/challenge/${challenge.token}/jwt?solution=${solution}`).pipe(
            retryWhen((errors: Observable<HttpErrorResponse>) => errors.pipe(
                    concatMap((error, index) => {
                        if (error.status === 410 || error.status === 403) {
                            this.refreshChallenge(challenge);
                            return throwError(() => error);
                        }

                        if (index === 2) {
                          return throwError(() => error);
                        }

                        return of(error).pipe(
                            delay(1000),
                        );
                    }),
                )),
        );
    }

    public refreshChallenge(challenge: Challenge) {
        challenge.refreshSubject.next();
    }

    public getImageChallenge(siteKey: string, hexColor: string, height: number): Observable<ImageChallenge | HttpErrorResponse> {
        let lastObjectUrl = '';
        const refreshSubject = new Subject<void>(); // When the refreshSubject is triggered, the whole observable is restarted
        return this.isStable.pipe(
            first(stable => stable),
            switchMap(() => refreshSubject.pipe(
              startWith(null), // This triggers the first request, without any next
            )),
            switchMap(() => this.fetchImageChallenge(siteKey, hexColor, height)), // Fetch the first image challenge (This is run immediately without any timer)
            expand((response) => { // The expand function is triggered for every value in the stream (the first is the fetch above)
                if (response instanceof HttpErrorResponse) {
                    return EMPTY; // An empty observable stops the expand function, since there are no values to recurse
                }

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return timer(parseInt(response.headers.get('Challenge-Expiry')!, 10) - Date.now()).pipe( // Wait for the timeout of the challenge
                    takeUntil(refreshSubject), // Don't refresh the challenge, if we have already manually triggered the refresh
                    switchMap(() => this.fetchImageChallenge(siteKey, hexColor, height)), // Fetch the challenge
                );
            }),
            switchMap((response): Observable<ImageChallenge | HttpErrorResponse> => {
                if (response instanceof HttpErrorResponse) {
                    return of(response); // If we have an error response, just emit that
                }

                URL.revokeObjectURL(lastObjectUrl); // We have to revoke object urls, a empty string doesn't trigger an error here
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                lastObjectUrl = URL.createObjectURL(response.body!); // Get the object URL, which is basically the image
                return of({ // Emit the challenge response
                    siteKey,
                    image: lastObjectUrl,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    token: response.headers.get('Challenge-Expiry')!,
                    refreshSubject,
                });
            }),
        );
    }

    private fetchImageChallenge(siteKey: string, hexColor: string, height: number) {
        // We need the current date as parameter, because angular somehow caches results internally?
        return this.http.get(`${this.baseUrl}/${siteKey}/challenge/image?hexColor=${hexColor}&height=${height}&${Date.now()}=`, { observe: 'response', responseType: 'blob' }).pipe(
            retryBackoff({ // Retry to get the image if we have connection problems
                initialInterval: 1000,
                maxRetries: 3,
            }),
            catchError((error: HttpErrorResponse) =>  // If we didn't get the image after x tried, just emit the error
                 of(error),
            ),
        );
    }

}
